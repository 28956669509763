<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edit Check')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('Color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('Color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.km"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Km')"
                  :state="errors.km ?false:null"
                  :class="errors.km ?'is-invalid':null"
                />
                <small
                  v-if="errors.km"
                  class="text-danger"
                >{{ errors.km[0] }}</small>
                <label>{{ $t('Km') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="data.new"
                  :value="true"
                  :state="errors.new ?false:null"
                >
                  {{ data.new ? $t('New Check') : $t('Old Check') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.datanew"
                class="text-danger"
              >{{ errors.datanew[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.plate_fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Plate_fee')"
                  :state="errors.plate_fee ?false:null"
                  :class="errors.plate_fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.plate_fee"
                  class="text-danger"
                >{{ errors.plate_fee[0] }}</small>
                <label>{{ $t('Plate_fee') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.renew_fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Renew_fee')"
                  :state="errors.renew_fee ?false:null"
                  :class="errors.renew_fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.renew_fee"
                  class="text-danger"
                >{{ errors.renew_fee[0] }}</small>
                <label>{{ $t('Renew_fee') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.contract_number"
                  class="mt-2"
                  :state="errors.contract_number ?false:null"
                  :placeholder="$t('Contract_number')"
                />
                <small
                  v-if="errors.contract_number"
                  class="text-danger"
                >{{ errors.contract_number[0] }}</small>
                <label>{{ $t('Contract_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Expiration_date') }}</label>
                <b-form-datepicker
                  v-model="data.expiration_date"
                  :placeholder="$t('Expiration_date')"
                  :state="errors.expiration_date ?false:null"
                />
                <small
                  v-if="errors.expiration_date"
                  class="text-danger"
                >{{ errors.expiration_date[0] }}</small>
              </div>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Checkins')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.checkin"
                :options="Checkins"
                :reduce="Checkins => Checkins.id"
                label="name"
              />
              <small
                v-if="errors.checkin"
                class="text-danger"
              >{{ errors.checkin[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Owners')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.owner"
                :options="Owners"
                :reduce="Owners => Owners.id"
                label="name"
              />
              <small
                v-if="errors.owner"
                class="text-danger"
              >{{ errors.owner[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Plates')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.plate"
                :options="Plates"
                :reduce="Plates => Plates.id"
                label="name"
              />
              <small
                v-if="errors.plate"
                class="text-danger"
              >{{ errors.plate[0] }}</small>
            </b-card>
          </b-col>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="update"
                  >
                    {{ $t('Update') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Checkins() { return this.$store.getters['checkin/GetElements'] },

    Owners() { return this.$store.getters['owner/GetElements'] },

    Plates() { return this.$store.getters['plate/GetElements'] },

    Element() { return this.$store.getters['check/GetElement'] },
  },
  created() {
    this.$store.dispatch('checkin/GetElements')

    this.$store.dispatch('owner/GetElements')

    this.$store.dispatch('plate/GetElements')

    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('check/GetElement', this.$route.params.check).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('check/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
